<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-row>
      <b-col
        cols="12"
        sm="4"
      >
        <router-link :to="{ name: 'user.reservations.pending' }">
          <iq-card>
            <template v-slot:body>
              <div class="d-flex d-flex align-items-center justify-content-between">
                <div>
                  <h2>{{ pending_reservation }}</h2>
                  <p class="fontsize-sm m-0">
                    Pending Reservations
                  </p>
                </div>
                <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                  <i class="ri-inbox-fill" /></div>
              </div>
            </template>
          </iq-card>
        </router-link>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SUserDashboard } from '@/services'

export default {
  name: 'UserDashboard',

  middleware: ['auth', 'user'],

  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },

  data () {
    return {
      pending_reservation: 0
    }
  },

  mounted () {
    core.index()
    this.fetchData()
  },

  methods: {
    async fetchData () {
      await SUserDashboard.get().then(({ data }) => {
        this.pending_reservation = data.pending_reservation
      })
    }
  }
}
</script>
